import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilePond = _resolveComponent("FilePond")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["file-upload-container", { pending: !_ctx.uploading }])
  }, [
    _createVNode(_component_FilePond, {
      name: "filepond[]",
      ref: "pond",
      "label-idle": `Drag & Drop your image or <span class="filepond--label-action"> Browse </span>`,
      "allow-multiple": false,
      "accepted-file-types": _ctx.acceptedFileTypes,
      maxFileSize: "20MB",
      server: _ctx.server,
      beforeAddFile: _ctx.beforeAddFile,
      files: _ctx.files,
      onInit: _ctx.filePondReady,
      onProcessfileabort: _ctx.fileAbort,
      onRemovefile: _ctx.fileAbort,
      imageResizeTargetWidth: 800,
      imageResizeTargetHeight: 800,
      allowImageResize: true,
      imageResizeUpscale: false,
      imageTransformVariantsIncludeDefault: true,
      imageCropAspectRatio: '3:2'
    }, null, 8 /* PROPS */, ["accepted-file-types", "server", "beforeAddFile", "files", "onInit", "onProcessfileabort", "onRemovefile"])
  ], 2 /* CLASS */))
}